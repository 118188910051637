import React, { useState, useEffect, createRef } from "react"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router" // Use this to retrieve the passed data
import moment from "moment"
import _map from "lodash/map"
import _head from "lodash/head"
import _get from "lodash/get"
import _noop from "lodash/noop"
import _isEmpty from "lodash/isEmpty"
import lottie from "lottie-web"
import confettiAnimation from "../animations/confetti.json"
import { getBookingConfirmationStatus } from "../utils/common"
import SEOHeader from "../components/seo-header"
import Layout from "../components/layout"
const BookingConfirmation = () => {
  let animationContainer = createRef()
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: confettiAnimation,
    })
    return () => {
      animationContainer.current = animationContainer.current || {}
      anim.destroy(animationContainer.current)
    } // optional clean up for unmounting
  }, [])
  const location = useLocation()
  const booking = location.state?.booking

  const [loadAnimation, setLoadAnimation] = useState(false)

  const { statusLabel, showMessage } = getBookingConfirmationStatus(booking)

  return (
    <Layout>
      <SEOHeader
        title={"Booking Confirmation | GoFloaters"}
        description={"Book Confirmation | GoFloaters"}
      ></SEOHeader>
      <div className="container">
        <div className="row justify-content-center">
          {!booking ? (
            <div className="col-md-6 mb-5" style={{ textAlign: "center" }}>
              <div>Loading...</div>
            </div>
          ) : (
            <div className="col-md-6 mb-5" style={{ textAlign: "center" }}>
              <p className="mb-2 bold" style={{ color: "#2798B5" }}>
                {statusLabel}
              </p>
              <p className="bold">{booking.bookingid}</p>

              <div
                className="d-flex justify-content-center"
                style={{ position: "relative" }}
              >
                <div
                  className="animation-container"
                  style={{ width: 200, height: 200 }}
                  ref={animationContainer}
                >
                  {/* Animation or success image */}
                </div>
                <h2
                  className="bold"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "#2798B5",
                  }}
                >
                  Yay!
                </h2>
              </div>

              <div
                className="py-2 px-2 mb-5"
                style={{ border: "1px dotted #2798B5", borderRadius: 10 }}
              >
                <div className="d-flex flex-row align-items-center">
                  <p className="bold">Booking Id:&nbsp; </p>
                  <p>{booking.bookingid}</p>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <p className="bold">Booking For:&nbsp; </p>
                  <p>{booking.spaceName}</p>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <p className="bold">Booking Date:&nbsp; </p>
                  <p>
                    {moment(booking.startTime).format("Do MMMM YYYY, hh:mma")}
                  </p>
                </div>
              </div>

              <a
                href="/bookings"
                style={{
                  display: "block",
                  backgroundColor: "#2798B5",
                  padding: "10px",
                  borderRadius: "4px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Track Booking
              </a>

              {showMessage ? (
                <p>
                  You will receive an SMS and email confirmation within 30 mins
                  if the space is available.
                </p>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default BookingConfirmation
